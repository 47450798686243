import React from "react";
import "./index.style.css";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="main">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/20241113_190326.jpg?alt=media&token=f06a9df2-c4f7-47ca-b37d-d8e470da2672"
          alt="..."
        />
        <div className="all-text">
          <h1>Bienvenue chez Esthétique Basilix !</h1>
          <p>
            Dans notre centre d'esthétique, nous combinons la technologie de
            pointe avec des années d'expérience et de passion dédiées aux soins
            de la peau. À chaque visite, nos experts en esthétique et bien-être
            sont prêts à personnaliser un plan de traitement adapté à vos
            besoins et objectifs spécifiques. Axés sur la qualité, le confort et
            l'excellence du service, nous nous engageons à répondre à vos
            attentes à chaque fois que vous nous rendez visite.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
