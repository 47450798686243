import React from "react";
import "./index.style.css";

const CartCadeau = () => {
  const mailtoLink = "mailto:esthetiquebasilix@gmail.com";

  return (
    <section id="cadeau" className="offers offers-cadeau ">
      <div className="offres-block-cadeau">
        {/* <h1>CARTE CADEAU</h1> */}
        {/* pentru oferte noi pe viitor */}
        <section className="offer-section">
          <div className="offer-container">
            <div className="offer-image">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/2024-12-23-16-33-38-556.jpg?alt=media&token=499e3edb-c918-4970-a842-813d858cf9d6"
                alt="Imagine Oferta"
              />
            </div>
            <div className="offer-text">
              <h2>
                Découvrez le cadeau parfait au Centre Esthétique Basilix !
              </h2>
              <p>
                Surprenez vos proches avec un cadeau unique ! La Carte Cadeau
                est la solution idéale pour offrir une expérience inoubliable.
                Cette carte peut être utilisée pour une large gamme de services,
                des soins du visage aux massages relaxants.
              </p>
              <p>
                Vous pouvez acheter la Carte Cadeau directement à notre centre
                situé au Basilix Shopping Center, au deuxième étage, ou, si vous
                préférez, vous pouvez passer commande par email à l'adresse{" "}
                <a href={mailtoLink} className="offre-email">
                  esthetiquebasilix@gmail.com
                </a>
              </p>
              <p>
                La Carte Cadeau sera envoyée à l'adresse indiquée ou pourra être
                achetée dans notre centre d'esthétique.
              </p>
              <p>Offrez des moments magnifiques !</p>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default CartCadeau;
