import React from "react";
import "./index.style.css";

const Banner = () => {
  return (
    <section id="banner" className="banner-section">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/asd.png?alt=media&token=92bed956-863f-46cf-a9e4-758ccf11ff4d"
        alt="asd"
        className="banner-image"
      />
      <div className="banner-description">
        Centre Esthétique Basilix accorde une attention particuliére aux besoins
        féminins de soins et de beauté.
      </div>

      <div className="banner-title-buutton">
        <a className="banner-custom-btn-desktop" href="#form">
          PRENDRE RENDEZ-VOUS
        </a>
      </div>
      <div className="banner-title">Centre Esthétique Basilix</div>
      <a className="banner-custom-btn ml-5 mr-5" href="#form">
        PRENDRE RENDEZ-VOUS
      </a>
    </section>
  );
};

export { Banner };
