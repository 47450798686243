import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import "./index.style.css";

const LoaderScreen = () => {
  const [loading, setLoading] = useState(true); // State for the loading screen

  useEffect(() => {
    // Simulate loading for a short duration (e.g., 2 seconds)
    const loadingTimer = setTimeout(() => {
      setLoading(false); // Hide loading screen after timeout
    }, 2000);

    // Cleanup timer on component unmount
    return () => clearTimeout(loadingTimer);
  }, []);

  return (
    <div className={`loading-screen ${loading ? "" : "displayLoader"}`}>
      <BeatLoader color="#ffffff" loading={loading} size={30} />
    </div>
  );
};

export default LoaderScreen;
