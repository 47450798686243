import React, { useEffect, useState } from "react";
import Button from "../../reusable/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMonths } from "date-fns";
import "./index.style.css";
import axios from "axios";

interface Reservation {
  name: string;
  surname: string;
  tel: string;
  email: string;
  service: string;
  data: string;
  time: string;
}

const services = [
  "ESSENTIEL - Soin du Visage",
  "GOLD - Soin du Visage par Hydrafacial Diamant",
  "DIAMOND - Soin du Visage par Hydrafacial Diamant",
  "PLATINUM - Soin du Visage par Hydrafacial Diamant",
  "Mésothérapie",
  "Peeling Laser",
  "Dépigmentation des sourcils et tatouages avec Pico-Laser",
  "Dépigmentation des taches",
  "Épilation définitive",
  "Épilation à la cire",
  "Élimination au laser des varices fines et de la cupérose",
  "Massage thérapeutique",
  "Drainage lymphatique",
  "Réflexologie plantaire",
];
const times = [
  "10h00",
  "11h00",
  "12h00",
  "13h00",
  "14h00",
  "15h00",
  "16h00",
  "17h00",
  "18h00",
];

export default function Form() {
  const [reservedDates, setReservedDates] = useState<Reservation[]>([]);
  const [formData, setFormData] = useState<{
    name: string;
    surname: string;
    tel: string;
    email: string;
    service: string;
    data: string;
    time: string;
  }>({
    name: "",
    surname: "",
    tel: "",
    email: "",
    service: "",
    data: "",
    time: "",
  });
  const [disableBtn, setDisableBtn] = useState(false);
  let isDisabled;

  const getReservedDates = async () => {
    try {
      const response = await axios.get(
        "https://esthetiquebasilixbackend.onrender.com/tasks/get"
      );
      setReservedDates(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    getReservedDates();
  }, []);

  useEffect(() => {
    getReservedDates();
  }, [formData.data, formData.service]);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://esthetiquebasilixbackend.onrender.com/tasks/save",
        {
          data: formData.data,
          email: formData.email,
          name: formData.name,
          service: formData.service,
          surname: formData.surname,
          tel: formData.tel,
          time: formData.time,
        }
      );
      alert("Inscription réussie!");

      await axios.get(
        "https://esthetiquebasilixbackend.onrender.com/sentemail",
        {
          params: {
            data: formData.data,
            email: formData.email,
            name: formData.name,
            service: formData.service,
            surname: formData.surname,
            tel: formData.tel,
            time: formData.time,
          },
        }
      );

      setFormData({
        name: "",
        surname: "",
        tel: "",
        email: "",
        service: "",
        data: "",
        time: "",
      });
      getReservedDates();
      console.log("success");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const dataToString = (data: Date | null) => {
    const date = data || new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    return dateString;
  };

  const isWeekday = (date: any) => {
    const day = date.getDay();
    return day !== 0;
  };

  useEffect(() => {
    const isSpecialEmail = formData.email === "Voicugeorgiana88@gmail.com";
    let hasFutureReservation = false;

    reservedDates.forEach((item) => {
      const itemDate = new Date(item.data);
      const currentDate = new Date();

      const isFutureDate =
        itemDate.setHours(0, 0, 0, 0) > currentDate.setHours(0, 0, 0, 0);

      if (isFutureDate && item.email === formData.email) {
        hasFutureReservation = true;
      }
    });

    if (isSpecialEmail) {
      setDisableBtn(false);
    } else if (hasFutureReservation) {
      alert(`Vous avez déjà un rendez-vous pour la date ${
        reservedDates.find((item) => item.email === formData.email)?.data
      }. 
      Si vous souhaitez modifier l'heure de votre rendez-vous, merci de nous contacter au numéro de téléphone ou à l'adresse e-mail indiqués dans la rubrique 'contact' de notre page. 
      Nous vous rappelons que toute non-présentation à la date et à l'heure convenues pourrait entraîner une perte d'accès à nos services à l'avenir. 
      Nous vous remercions de votre compréhension et de votre ponctualité!`);
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [formData.email, reservedDates, setDisableBtn]);

  const isTimePast = (
    time: string,
    selectedDate: string,
    currentDate: string
  ): boolean => {
    const [hours] = time.split("h").map(Number);
    const now = new Date();
    const currentHour = now.getHours();
    if (selectedDate === currentDate) {
      if (hours <= currentHour) {
        return true;
      }
    }
    return false;
  };

  return (
    <section id="form" className="form">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#97afbc"
          fill-opacity="1"
          d="M0,288L60,245.3C120,203,240,117,360,69.3C480,21,600,11,720,16C840,21,960,43,1080,48C1200,53,1320,43,1380,37.3L1440,32L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        ></path>
      </svg>
      <div className="form-main">
        <div className="form-container">
          <h2>Prendre rendez-vous</h2>
          <form onSubmit={handleSubmit} id="form" className="flex flex-col">
            <input
              type="text"
              className="form-control"
              value={formData.name}
              placeholder="Nom"
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              required
            />
            <input
              type="text"
              className="form-control"
              value={formData.surname}
              placeholder="Prénom"
              onChange={(e) =>
                setFormData({ ...formData, surname: e.target.value })
              }
              required
            />
            <input
              type="text"
              className="form-control"
              value={formData.tel}
              placeholder="GSM"
              onChange={(e) =>
                setFormData({ ...formData, tel: e.target.value })
              }
              required
            />
            <input
              type="text"
              className="form-control"
              value={formData.email}
              placeholder="E-mail"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              required
            />
            <select
              className="form-control"
              value={formData.service}
              onChange={(e) =>
                setFormData({ ...formData, service: e.target.value, time: "" })
              }
              required={true}
            >
              <option value="">Sélectionner le service</option>
              {services.map((service) => (
                <option
                  key={service}
                  value={service}
                  disabled={
                    service === "Massage thérapeutique" ||
                    service === "Drainage lymphatique" ||
                    service === "Réflexologie plantaire"
                  }
                >
                  {service}
                </option>
              ))}
            </select>

            <DatePicker
              selected={formData.data}
              filterDate={isWeekday}
              minDate={new Date()}
              excludeDates={[new Date("2024-08-15")]}
              maxDate={addMonths(new Date(), 2)}
              onChange={(date: any) =>
                setFormData({ ...formData, data: dataToString(date) })
              }
              className="form-control"
              placeholderText="Date"
              required
            />

            <select
              className="form-control"
              value={formData.time}
              onChange={(e) =>
                setFormData({ ...formData, time: e.target.value })
              }
              disabled={!formData.data}
              required
            >
              <option value="">Heure</option>
              {times.map((time) => {
                const isReserved = reservedDates.some(
                  (reservation) =>
                    reservation.data === formData.data &&
                    reservation.time === time
                );

                isDisabled =
                  isReserved ||
                  isTimePast(time, formData.data, dataToString(new Date()));

                return (
                  <option key={time} value={time} disabled={isDisabled}>
                    {time}
                  </option>
                );
              })}
            </select>
            <Button
              type="submit"
              title="Prendre rendez-vous"
              disableBtn={disableBtn}
            />
          </form>
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/esthetiquebasilix-7e531.appspot.com/o/retoques-t.jpg?alt=media&token=24e2a8f3-6244-4db3-97f9-793feb776846"
          alt="..."
        />
      </div>
    </section>
  );
}
