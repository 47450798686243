// import React from "react";
// import "./index.style.css";

// const Header = () => {
//   const [current, setCurrent] = React.useState("banner");
//   // const [currentLanguage, setCurrentLanguage] = React.useState("EN");
//   const handleClick = () => {
//     window.open("https://www.facebook.com/CentreEsthetiqueBasilix", "_blank");
//   };

//   return (
//     <nav
//       id="navbar"
//       className="navbar navbar-expand-lg fixed-top navbar-light px-5"
//     >
//       <a className="navbar-brand" href="#banner" aria-label="Go to banner"></a>
//       <button
//         className="navbar-toggler"
//         type="button"
//         data-toggle="collapse"
//         data-target="#navbarText"
//         aria-controls="navbarText"
//         aria-expanded="false"
//         aria-label="Toggle navigation"
//       >
//         <span className="navbar-toggler-icon"></span>
//       </button>
//       <div className="collapse navbar-collapse" id="navbarText">
//         <ul className="navbar-nav mr-auto">
//           <li className={`nav-item ${current === "banner" ? "active" : ""}`}>
//             <a
//               className="nav-link"
//               href="#banner"
//               onClick={() => setCurrent("banner")}
//             >
//               HOME<span className="sr-only">(current)</span>
//             </a>
//           </li>
//           <li className={`nav-item ${current === "about" ? "active" : ""}`}>
//             <a
//               className="nav-link"
//               href="#about"
//               onClick={() => setCurrent("about")}
//             >
//               À PROPOSE DE NOUS
//             </a>
//           </li>
//           <li
//             className={`nav-item ${current === "allServices" ? "active" : ""}`}
//           >
//             <a
//               className="nav-link"
//               href="#allServices"
//               onClick={() => setCurrent("allServices")}
//             >
//               NOS PRESENTATION
//             </a>
//           </li>
//           <li className={`nav-item ${current === "offers" ? "active" : ""}`}>
//             <a
//               className="nav-link"
//               href="#offers"
//               onClick={() => setCurrent("offers")}
//             >
//               NOS OFFRES
//             </a>
//           </li>
//           {/* <li className="nav-item">
//             <a className="nav-link" href="/">
//               Gallery
//             </a>
//           </li> */}
//           <li className={`nav-item ${current === "contact" ? "active" : ""}`}>
//             <a
//               className="nav-link"
//               href="#contact"
//               onClick={() => setCurrent("contact")}
//             >
//               CONTACT
//             </a>
//           </li>
//         </ul>
//         {/* <div className="language-container">
//           <div
//             className={`language-item ${
//               currentLanguage === "EN" ? "active" : ""
//             }`}
//             onClick={() => setCurrentLanguage("EN")}
//           >
//             EN
//           </div>
//           <div className="language-item-divider">/</div>
//           <div
//             className={`language-item ${
//               currentLanguage === "FR" ? "active" : ""
//             }`}
//             onClick={() => setCurrentLanguage("FR")}
//           >
//             FR
//           </div>
//           <div className="language-item-divider">/</div>
//           <div
//             className={`language-item ${
//               currentLanguage === "NL" ? "active" : ""
//             }`}
//             onClick={() => setCurrentLanguage("NL")}
//           >
//             NL
//           </div>
//         </div> */}
//         <span className="navbar-text">
//           <a href="/" onClick={handleClick}>
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="24"
//               height="24"
//               fill="blue"
//               className="bi bi-facebook"
//               viewBox="0 0 16 16"
//             >
//               <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
//             </svg>
//           </a>
//         </span>
//       </div>
//     </nav>
//   );
// };

// export default Header;

import React from "react";
import { useRef } from "react";
import "./index.style.css";

function Navbar() {
  const navRef = useRef<HTMLDivElement>(null);
  // const [currentLanguage, setCurrentLanguage] = React.useState("EN");
  const handleClick = () => {
    window.open("https://www.facebook.com/CentreEsthetiqueBasilix", "_blank");
  };
  const handleClickInsta = () => {
    window.open(
      "https://www.instagram.com/centre.esthetique.basilix?igsh=cGM3bDl0NGx2emw3",
      "_blank"
    );
  };
  const showNavbar = () => {
    if (navRef.current) {
      navRef.current.classList.toggle("responsive_nav");
    }
  };

  return (
    <header>
      <a className="navbar-brand" href="#banner" aria-label="Go to banner"></a>
      <a className="nav-link-navbar promotion-button" href="#cadeau">
        CARTE CADEAU
      </a>
      <nav ref={navRef}>
        <a className="nav-link" href="#banner" onClick={showNavbar}>
          ACCUEIL<span className="sr-only">(current)</span>
        </a>

        <a className="nav-link" href="#about" onClick={showNavbar}>
          À PROPOS DE NOUS
        </a>

        <a className="nav-link" href="#allServices" onClick={showNavbar}>
          NOS SERVICES
        </a>
        <a className="nav-link" href="#products" onClick={showNavbar}>
          NOS PRODUITS
        </a>
        <a className="nav-link" href="#offers" onClick={showNavbar}>
          NOS OFFRES
        </a>

        {/* <li className="nav-item">
            <a className="nav-link" href="/">
              Gallery
            </a>
          </li> */}

        <a className="nav-link" href="#contact" onClick={showNavbar}>
          CONTACT
        </a>

        <a
          className="nav-link promotion-button"
          href="#cadeau"
          onClick={showNavbar}
        >
          CARTE CADEAU
        </a>
        <div>
          <span className="navbar-text">
            <a href="/" onClick={handleClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="blue"
                className="bi bi-facebook"
                viewBox="0 0 16 16"
              >
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
              </svg>
            </a>
          </span>
          <span className="navbar-text">
            <a href="/" onClick={handleClickInsta}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="35px"
                height="35px"
              >
                <radialGradient
                  id="yOrnnhliCrdS2gy~4tD8ma"
                  cx="19.38"
                  cy="42.035"
                  r="44.899"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#fd5" />
                  <stop offset=".328" stop-color="#ff543f" />
                  <stop offset=".348" stop-color="#fc5245" />
                  <stop offset=".504" stop-color="#e64771" />
                  <stop offset=".643" stop-color="#d53e91" />
                  <stop offset=".761" stop-color="#cc39a4" />
                  <stop offset=".841" stop-color="#c837ab" />
                </radialGradient>
                <path
                  fill="url(#yOrnnhliCrdS2gy~4tD8ma)"
                  d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20 c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20 C42.014,38.383,38.417,41.986,34.017,41.99z"
                />
                <radialGradient
                  id="yOrnnhliCrdS2gy~4tD8mb"
                  cx="11.786"
                  cy="5.54"
                  r="29.813"
                  gradientTransform="matrix(1 0 0 .6663 0 1.849)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#4168c9" />
                  <stop offset=".999" stop-color="#4168c9" stop-opacity="0" />
                </radialGradient>
                <path
                  fill="url(#yOrnnhliCrdS2gy~4tD8mb)"
                  d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20 c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20 C42.014,38.383,38.417,41.986,34.017,41.99z"
                />
                <path
                  fill="#fff"
                  d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5 s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"
                />
                <circle cx="31.5" cy="16.5" r="1.5" fill="#fff" />
                <path
                  fill="#fff"
                  d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12 C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"
                />
              </svg>
            </a>
          </span>
        </div>
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </button>
      </nav>

      <button className="nav-btn" onClick={showNavbar}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
          />
        </svg>
      </button>
    </header>
  );
}

export default Navbar;
